import {Swiper, Navigation} from "swiper";

$(function () {
    new Swiper('.featured-blog-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        speed: 500,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            575: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 36,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 0,
            },
        },
    });
});
