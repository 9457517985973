$(document).ready(function () {
    let $w_window = $(window).width();

    let $items = $('[data-element="collapse-item"]');

    if($items.length > 0) {
        $items.on('click', function (e) {
            e.preventDefault();

            let $element = $(this);
            let $target = $($element.data('bs-target'));

            if ($w_window < 1200) {
                $element.attr('aria-expanded', !$target.hasClass('show'))
                $target.collapse('toggle');
            }
        });

        function openCollapsedItems() {
            $items.each(function (i) {
                let $element = $(this);

                let bs_target = $element.data('bs-target');
                let $target = $(bs_target);

                $element.attr('aria-expanded', true)
                $target.addClass('show');

            });
        }

        function closeCollapseItems() {
            $items.each(function (i) {
                let $element = $(this);

                let bs_target = $element.data('bs-target');
                let $target = $(bs_target);

                $element.attr('aria-expanded', false)
                $target.removeClass('show');

            });
        }

        window.onresize = (e) => {
            $w_window = $(window).width();

            if ($w_window > 768) {
                openCollapsedItems();
            }

            if ($w_window <= 768) {
                closeCollapseItems()
            }
        }

        window.onload = () => {
            $w_window = $(window).width();

            if ($w_window > 768) {
                openCollapsedItems();
            }

            if ($w_window <= 768) {
                closeCollapseItems();
            }
        }
    }
});
